import { createRouter, createWebHistory } from "vue-router";
import Inicio from "../views/Inicio.vue";
import Detalle from "../views/DetallePropiedad.vue";
import Buscar from "../views/buscar.vue";
import Ficha from "../views/crearpdf.vue";

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio,
  },
  {
    path: "/propiedad/:url",
    name: "Detalle",
    component: Detalle,
    props: true,
  },
  {
    path: "/buscar",
    name: "Buscar",
    component: Buscar,
  },
  {
    path: "/ficha-tecnica",
    name: "Ficha",
    component: Ficha,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
