<template>
  <div class="home">
    <div class="slider-section">
      <div class="item-slide">
        <div
          class="carr-inicio"
          style="
            background-image: url('https://fincabienesraices.com.mx/Admin/imagenes/portadas/LogoFotoVX1561 18.jpg');
          "
        >
          <div class="carousel-caption">
            <div class="slider-content">
              <h4>$8, 490, 000.00 MXN</h4>
              <h3>OFICINA EN VENTA COL. POLANCO</h3>
              <p></p>
              <p>
                Excelente y céntrica oficina en venta en col. Polanco, en frente
                de l...
              </p>
              <p></p>
              <a
                href="propiedad/oficina-en-venta-col.-polanco"
                title="caption-arrow"
                class="caption-arrow"
              >
                <ion-icon name="arrow-forward"></ion-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="search-section" class="search-section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-9 p_l_z">
            <select name="tipo" id="tipo">
              <option value>Tipo</option>

              <option>fhghfg</option>
            </select>
            <select name="ubicacion" id="ubicacion">
              <option value>Ubicación</option>
            </select>
            <select name="colonia" id="colonia">
              <option value>Colonia</option>

              <option
                style="text-transform: uppercase"
                value="<?=$row['colonia']?>"
              >
                jyujy
              </option>
            </select>
            <select name="op" id="operacion">
              <option value>Operacion</option>
              <option value="Renta">Renta</option>
              <option value="Venta">Venta</option>
              <option value="Preventa">Preventa</option>
            </select>
            <select name="recamaras" id="recamaras">
              <option value>Recamaras</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>

            <select name="estacionamiento" id="estacionamiento">
              <option value>Estacionamiento</option>
              <option value="1">1 Lugar</option>
              <option value="2">2 Lugares</option>
              <option value="3">3 Lugares</option>
              <option value="4">4 Lugares</option>
              <option value="5">5 Lugares</option>
            </select>

            <select name="banos" id="banos">
              <option value>Baños</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <select name="medbanos" id="mbanos">
              <option value>1/2 Baños</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <input
              type="number"
              list="minprecios"
              name="minprecio"
              id="minprecio"
              placeholder="Precio Minimo"
            />

            <input
              type="number"
              list="maxprecios"
              name="max-precio"
              id="maxprecio"
              placeholder="Precio Máximo"
            />
          </div>

          <div class="col-md-2 col-sm-3">
            <div class="section-header">
              <h3><span>Buscar</span>Propiedades</h3>
              <button
                title="search"
                class="btn-principal"
                onClick="buscarBtn()"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="rent-and-sale-section" class="rent-and-sale-section">
      <div class="container">
        <div class="rent-property row">
          <div class="col-md-3">
            <div class="section-header">
              <h3><span>Propiedades</span>En Preventa</h3>
              <p>Nuestra lista de propiedades más destacadas</p>
            </div>
          </div>
          <div class="col-md-9 p_r_z">
            <div id="rent-property-block" class="rent-property-block propiedades-inicio">
              <div class="container">
              <carousel :settings="settings"  :breakpoints="breakpoints" :autoplay="2000">
                <slide v-for="preventa in preventas" :key="preventa">
                  <div class="carousel__item">
                    <div class=" rent-block">
                      <div class="property-main-box" style="height: auto">
                        <div class="property-images-box">
                          <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: preventa.url },
                            }"
                          >
                            
                              <img
                                :src="
                                  `https://fincabienesraices.com.mx/Admin/imagenes/` +
                                  preventa.id +
                                  `/thumb-portada/` +
                                  preventa.img_perfil
                                "
                                alt="Image"
                                data-class="img-fluid"
                                style="height: 170px"
                              />
                            
                          </router-link>
                          <h4 style="font-size: 15px">
                            ${{ formatoNumero(preventa.precio) }}
                            {{ preventa.moneda }}
                          </h4>
                        </div>
                        <div class="clearfix"></div>
                        <div class="property-details">
                          <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: preventa.url },
                            }"
                          >
                          {{ preventa.titulo }}
                          </router-link>
                          <ul>
                            <li>
                              <i class="fa fa-expand"></i>
                            </li>
                            <li>
                              <i
                                ><img
                                  :src="require('@/assets/images/bed-icon.png')"
                                  alt="bed-icon"
                              /></i>
                            </li>
                            <li>
                              <i
                                ><img
                                  :src="
                                    require('@/assets/images/bath-icon.png')
                                  "
                                  alt="bath-icon"
                              /></i>
                            </li>
                          </ul>
                        </div>
                        <div class="property-footer">Ver Propiedad</div>
                      </div>
                    </div>
                  </div>
                </slide>

                <template #addons>
                  <navigation />
                  <pagination />
                </template>
              </carousel>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="sale-property row">
          <div class="col-md-3">
            <div class="section-header">
              <h3><span>Propiedades</span>En Venta</h3>
              <p>Nuestra lista de propiedades más destacadas</p>
            </div>
          </div>
          <div class="col-md-9 p_r_z">
            <div id="sale-property-block" class="sale-property-block propiedades-inicio">
              <div class="container">
              <carousel :items-to-show="3" :autoplay="2000" :loop="true">
                <slide v-for="preventa in ventas" :key="preventa">
                  <div class="carousel__item">
                    <div class="col-md-12 rent-block">
                      <div class="property-main-box" style="height: auto">
                        <div class="property-images-box">
                          <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: preventa.url },
                            }"
                          >
                          
                              <img
                                :src="
                                  `https://fincabienesraices.com.mx/Admin/imagenes/` +
                                  preventa.id +
                                  `/thumb-portada/` +
                                  preventa.img_perfil
                                "
                                alt="Image"
                                data-class="img-fluid"
                                style="height: 170px"
                              />
                         
                          </router-link>
                          <h4 style="font-size: 15px">
                            ${{ formatoNumero(preventa.precio) }}
                            {{ preventa.moneda }}
                          </h4>
                        </div>
                        <div class="clearfix"></div>
                        <div class="property-details">
                          <a
                            title="<?=$row['titulo']?>"
                            href="propiedad/<?=$row['url']?>"
                            >{{ preventa.titulo }}</a
                          >
                          <ul>
                            <li>
                              <i class="fa fa-expand"></i>
                            </li>
                            <li>
                              <i
                                ><img
                                  :src="require('@/assets/images/bed-icon.png')"
                                  alt="bed-icon"
                              /></i>
                            </li>
                            <li>
                              <i
                                ><img
                                  :src="
                                    require('@/assets/images/bath-icon.png')
                                  "
                                  alt="bath-icon"
                              /></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </slide>
                 <template #addons>
                  <navigation />
                </template>
              </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="featured-section" class="featured-section container-fluid p_z">
      <div class="container">
        <div class="section-header">
          <p>Trending</p>
          <h3>Inmuebles Destacados</h3>
        </div>
        <div id="featured-property" class="featured-property row">
          <div class="container">
<carousel :items-to-show="1" >
            <slide v-for="prop in destacadas" :key="prop" >
              <div class="item row">
                <div class="col-md-6 rent-block">
                  <div class="property-main-box" style="height: auto">
                    <div class="property-image-dest property-images-box">
                      <span>
                        <ion-icon name="star"></ion-icon>
                        <ion-icon name="star"></ion-icon>
                        <ion-icon name="star"></ion-icon>
                        <ion-icon name="star"></ion-icon>
                        <ion-icon name="star"></ion-icon>
                      </span>
                      <a
                        title="<?=$row['titulo']?>"
                        href="propiedad/<?=$row['url']?>"
                      >
                        <img
                          style="height: 351px"
                          :src="
                            `https://fincabienesraices.com.mx/Admin/imagenes/` +
                            prop.id +
                            `/thumb-portada/` +
                            prop.img_perfil
                          "
                          alt="featured"
                        />
                      </a>
                      <h4></h4>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <!-- Property Main Box -->
                </div>
                <div class="col-md-6">
                  <div class="property-details">
                    <a
                      title="<?=$row['titulo']?>"
                      href="propiedad/<?=$row['url']?>"
                    >
                      {{ prop.titulo }}
                    </a>
                      {{ prop.descripcion.substr(0,180) }}...
                    <hr />
                    <ul>
                      <li><i class="fa fa-expand"></i></li>

                      <li>
                        <i
                          ><img
                            :src="require('@/assets/images/bed-icon.png')"
                            alt="bed-icon"
                        /></i>
                      </li>

                      <li>
                        <i
                          ><img
                            :src="require('@/assets/images/bath-icon.png')"
                            alt="bath-icon"
                        /></i>
                      </li>
                    </ul>
                    <hr />
                    <div class="row">
                      <div class="col-md-5">
                        <p class="h5">
                          <span style="font-size: 13px"
                            >¿Quieres conocer más propiedades?</span
                          >
                          <br /><br />
                          Suscríbete a nuestro FincaNews
                        </p>
                      </div>
                      <div class="col-md-7">
                        <div class="destacado-feedback-form">
                          <input
                            type="text"
                            id="destacada-news<?=$row['id']?>"
                            name="contact-name "
                            placeholder="Correo Electrónico"
                            required=""
                          />
                          <button
                            data-toggle="modal"
                            data-target="#newsletter-modal"
                            onClick="newsletter('destacada-news<?=$row['id']?>')"
                            class="mt-2 btn-principal"
                          >
                            Suscribirme
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
             <template #addons>
                  <navigation />
                </template>
          </carousel>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import axios from "axios";
 
export default {
  data() {
    return {
      preventas: [],
      ventas: [],
      destacadas: [],
      settings: {
        itemsToShow: 1,
      },
       breakpoints: {
      // 700px and up
      400: {
        itemsToShow: 1,
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
      },
    }
    };
  },
  methods: {
    formatoNumero(numero) {
      var n = numero;
      n = String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");

      return n;
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  
  mounted() {
    axios
      .get("https://fincabienesraices.mx/api/propiedad/preventa")
      .then((response) => {
        if (response.data.error) {
          console.log("Error al consultar las propiedades en preventa");
        } else {
          console.log(response.data);
          this.preventas = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://fincabienesraices.mx/api/propiedad/venta")
      .then((response) => {
        if (response.data.error) {
          console.log("Error al consultar las propiedades en venta ");
        } else {
          console.log(response.data);
          this.ventas = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://fincabienesraices.com.mx/api/propiedad/destacada")
      .then((response) => {
        if (response.data.error) {
          console.log("Error al consultar las propiedades en venta ");
        } else {
          console.log(response.data);
          this.destacadas = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.carrusel {
  height: auto;
  width: 100%;
  position: relative;
}
.slide-info img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.item {
  margin-right: 15px;
}
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
.carousel__prev, .carousel__next{
background-color: hsl(0, 90%, 48%) !important;
}
.property-images-box  img {
  width: 280px;
  height: 170px;
  object-fit: cover;
}
.property-image-dest img{
  width: 450px;
  height: 376px;
  object-fit: cover;
}
.carousel__slide {
    padding: 10px;
}
</style>

