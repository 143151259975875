<template>
  <div class="page-content">
    <!-- Banner Section -->

    <!-- Property Listing Section -->
    <div id="property-listing" class="property-listing inicio-banner">
      <div class="container">
        <div class="row">
          <div class="property-left col-md-9 col-sm-6 p_l_z content-area">
            <div class="section-header p_l_z">
              <div class="col-md-10 col-sm-10 p_l_z">
                <p>Lista de</p>
                <h3>Propiedades</h3>
              </div>
            </div>
            <button @click="urlAction">Enviar datos</button>

            <div></div>
            <div class="property-listing-row row">
              <!-- Col-md-4 -->
              <div class="col-md-4 col-sm-12 rent-block">
                <!-- Property Main Box -->
                <div class="property-main-box">
                  <div class="property-images-box">
                    <span style="font-size: 10px; width: 95px; z-index: 80">
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                    </span>
                    <a
                      title="<?=$row['titulo']?>"
                      href="<?=URL_SITIO?>/propiedad/<?=$row['url']?>"
                    >
                      <figure>
                        <img
                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                          alt="Image"
                          data-class="img-fluid"
                          style="height: 170px"
                        />
                      </figure>
                    </a>
                    <h4 style="font-size: 10px">$12432432432</h4>
                  </div>
                  <div class="clearfix"></div>
                  <div class="property-details">
                    <a
                      title="<?=$row['titulo']?>"
                      href="<?=URL_SITIO?>/propiedad/<?=$row['url']?>"
                      >froienfjernf newofder</a
                    >
                    <ul></ul>
                    <p>fonewokewd jkwendewd..</p>
                  </div>
                  <div class="propiedad-footer d-flex justify-content-between">
                    <a
                      target="_blank"
                      href="https://api.whatsapp.com/send?phone=+525584932033text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20p"
                      class="wsp"
                      >Whatsapp</a
                    >
                    <a href="#" class="cnt">Contactar</a>
                  </div>
                </div>
                <!-- Property Main Box /- -->
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 p_r_z property-sidebar widget-area">
            <aside class="widget widget-search">
              <h2 class="widget-title">Buscar<span>Propiedad</span></h2>
              <!--form action="<?=URL_SITIO?>/buscar/" method="post"-->
              <select name="tipo" id="tipo">
                <option value>Tipo</option>
              </select>
              <select name="ubicacion" id="ubicacion">
                <option value>Ubicación</option>
              </select>
              <select name="colonia" id="colonia">
                <option value>Colonia</option>
              </select>
              <select name="op" id="operacion">
                <option value>Operacion</option>
                <option value="Renta">Renta</option>
                <option value="Venta">Venta</option>
                <option value="Preventa">Preventa</option>
              </select>
              <select name="recamaras" id="recamaras">
                <option value>Recamaras</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
              </select>

              <select name="estacionamiento" id="estacionamiento">
                <option value>Estacionamiento</option>
                <option value="1">1 Lugar</option>
                <option value="2">2 Lugares</option>
                <option value="3">3 Lugares</option>
                <option value="4">4 Lugares</option>
                <option value="5">5 Lugares</option>
              </select>

              <select name="banos" id="banos">
                <option value>Baños</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              <select name="medbanos" id="mbanos">
                <option value>1/2 Baños</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              <input
                type="number"
                list="minprecios"
                name="minprecio"
                id="minprecio"
                placeholder="Precio Minimo"
              />
              <!--datalist name="min-precio" id="minprecios">
						<option value>Min Precio</option>
						<option value="100000">100,000</option>
						<option value="200000">200,000</option>
						<option value="300000">300,000</option>
						<option value="400000">400,000</option>
						<option value="1000000">1,000,000</option>
					</datalist-->
              <input
                type="number"
                list="maxprecios"
                name="max-precio"
                id="maxprecio"
                placeholder="Precio Máximo"
              />
              <!--datalist id="maxprecios">
						<option value>Max Precio</option>
						<option value="1000000">$1000000</option>
						<option value="1500000">$1500000</option>
						<option value="2000000">$2000000</option>
						<option value="2500000">$2500000</option>
						<option value="+">Mas de 2500000</option>
					</datalist-->

              <button onClick="buscarBtn()" class="btn">Buscar Ahora</button>
            </aside>
          </div>
        </div>
      </div>
    </div>
    <!-- Property Listing Section /- -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  mounted() {
    console.log(this.$route.query.qry);
  },
  methods: {
    urlAction() {
      this.$router.push({ path: "/buscar", query: { qry: "private" } });
    },
  },
};
</script>

<style></style>
