<template>
 <metainfo>
      <template v-slot:title="{ content }">{{ content }} - Yay!</template>
    </metainfo>
  <div class="page-content" id="propiedadpdf">
    <div class="property-main-details">
      <div v-if="cargando">
        <div v-if="errorcargar">
          <h3>No se cargo nada</h3>
        </div>
      </div>
      <div v-if="!errorcargar" class="container">
        <div class="property-header">
          <h3>
            {{ titulo }} <span>En {{ operacion }}</span>
          </h3>

          <ul>
            <li>${{ formatoNumero(precio) }} {{ moneda }}</li>
            <li>Producto ID : 15656</li>
            <li v-if="recamaras != '-1'">
              <span
                ><ion-icon name="bed-outline"></ion-icon>{{ recamaras }}</span
              >
            </li>
            <li>
              <span><ion-icon name="car-outline"></ion-icon> 4</span>
            </li>
          </ul>
          <div class="ver-mob"><br /><br /><br /></div>
          <a title="Imprimir ficha técnica" href="/ficha-tecnica/?id="
            ><ion-icon name="send-outline"></ion-icon
            ><i class="fa fa-print"></i> Generar Ficha Técnica</a
          >
        </div>
        <div class="property-details-content container-fluid p_z row">
          <div class="col-md-6 col-sm-6 p_z">
            <div class="image-detalle-prop">
              <img
                :src="
                  `https://fincabienesraices.com.mx/Admin/imagenes/` +
                  id +
                  `/` +
                  img_perfil
                "
                alt="Slide"
                style="width: 100%; margin-bottom: 30px; border-radius: 15px"
              />
            </div>
            <carousel :items-to-show="3" class="container">
              <slide v-for="slide in carruselSlides" :key="slide">
                <div style="width: 150px; height: 100px">
                  <img
                    :src="slide"
                    alt=""
                    srcset=""
                    style="object-fit: cover"
                  />
                </div>
              </slide>
              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
            <div class="single-property-details">
              <h3>Descripción</h3>
              <p>{{ descripcion }}</p>
            </div>
            <div class="general-amenities pull-left">
              <h3>Caracteristicas generales</h3>
              <div class="amenities-list pull-left">
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <div class="amenities-checkbox">
                    <input type="checkbox" id="checkbox-1" checked />
                    <label for="checkbox-1">Hola</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              col-md-6 col-sm-6
              p_z
              property-sidebar
              single-property-sidebar
            "
          >
            <aside class="widget widget-search">
              <h2 class="widget-title">
                ¿Quieres más información?<span>Contáctanos</span>
              </h2>
              <form
                method="post"
                action="<?=URL_SITIO?>/post-detalles-propiedad"
              >
                <input
                  type="text"
                  placeholder="Nombre Completo"
                  name="nombre"
                  required
                />
                <input
                  type="text"
                  placeholder="Correo"
                  name="correo"
                  required
                />
                <input type="text" placeholder="Teléfono" name="tel" required />
                <textarea placeholder="Mensaje" name="msj" required></textarea>
                <input type="hidden" name="url" value="<?=$urlFinalS?>" />
                <input
                  type="hidden"
                  name="prop"
                  value="<?=$resProp['titulo']?>"
                />
                <div class="row">
                  <div class="col-md-6">
                    <input
                      type="submit"
                      value="Enviar información"
                      class="btn"
                    />
                  </div>
                  <div class="col-md-6">
                    <a
                      href="https://api.whatsapp.com/send?phone=+525584932033&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20propiedad%20*<?=$urlFinalW?>*"
                      ><button type="button" class="wsp-prop">
                        Enviar Whatsapp
                      </button></a
                    >
                  </div>
                </div>
              </form>
            </aside>
            <button class="wsp-prop" title="print">
              <i class="fa fa-whatsapp"></i> Compartir Ficha por Whatsapp
            </button>
            <div class="property-direction pull-left">
              <div class="property-map">
                <h3>Compartir esta propiedad :</h3>
                <ul>
                  <li>
                    <a
                      href="javascript: void(0);"
                      onClick="window.open('https://twitter.com/intent/tweet?text=<?=$actual_link?>','ventanacompartir', 'toolbar=0, status=0, width=650, height=450');"
                      title="twitter"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="javascript: void(0);"
                      onclick="window.open('http://www.facebook.com/sharer.php?u=<?=$actual_link?>','ventanacompartir', 'toolbar=0, status=0, width=650, height=450');"
                      title="facebook"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="whatsapp://send?text=<?=$actual_link?>"
                      ><i class="fa fa-whatsapp"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <div class="property-map">
                <div id="gmap" class="mapping"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import axios from "axios";
import { useRoute } from "vue-router";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useMeta } from 'vue-meta'
export default {
  setup () {
    useMeta({
      title: 'My Example gcvytyvguvyuvyut7uvgy gy tyc',
      htmlAttrs: {
        lang: 'es',
        amp: true
      }
    })
  },
  methods: {
    formatoNumero(numero) {
      var n = numero;
      n = String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");

      return n;
    },

    crearpdf() {
      window.html2canvas = html2canvas;
      var doc = new jsPDF("p", "pt", "a4");
      doc.html(document.querySelector("#propiedadpdf"), {
        callback: function (pdf) {
          pdf.save("testpdf");
        },
      });
    },
  },
  data() {
    return {
      id: "",
      fecha_creacion: "",
      titulo: "",
      descripcion: "",
      precio: "",
      moneda: "",
      operacion: "",
      tipo: "",
      piso: "",
      pisos_totales: "",
      departamentos_totales: "",
      recamaras: "",
      vestidores: "",
      banos_completos: "",
      banos_medios: "",
      estacionamientos: "",
      mtrs_construccion: "",
      mtrs_terreno: "",
      antiguedad: "",
      img_perfil: "",
      caracteristicas: "",
      estado: "",
      ciudad: "",
      colonia: "",
      calle: "",
      num_ext: "",
      num_int: "",
      coordenadas: "",
      url: "",
      whatsapp: "",
      cargando: true,
      errorcargar: false,
      carruselSlides: [],
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted() {
    const route = useRoute();
    const urlf = route.params.url;

    axios
      .get("https://fincabienesraices.mx/api/propiedad/?url=" + urlf)
      .then((response) => {
        if (response.data.error) {
          this.errorcargar = true;
        } else {
          const propiedad = response.data;
          this.id = propiedad.id;
          this.img_perfil = propiedad.img_perfil;
          this.descripcion = propiedad.descripcion;
          this.recamaras = propiedad.recamaras;
          this.estacionamientos = propiedad.estacionamientos;
          this.moneda = propiedad.moneda;
          this.titulo = propiedad.titulo;
          document.title = propiedad.titulo + " - Finca Bienes Raices";
          this.operacion = propiedad.operacion;
          this.precio = propiedad.precio;
          console.log(JSON.parse(response.data.imagenes));
          const imges = JSON.parse(response.data.imagenes);
          var link = document.createElement('meta');
          link.setAttribute('property', 'og:url');
          link.content = document.location;
          document.getElementsByTagName('head')[0].appendChild(link);
        
          this.carruselSlides = [];
          imges.map((itemres) => {
            var pushcc = encodeURI(
              "https://fincabienesraices.com.mx/Admin/imagenes/" +
                response.data.id +
                "/" +
                itemres
            );
            console.log(pushcc);
            this.carruselSlides.push(pushcc);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.image-detalle-prop img{
  height: 400px;
  object-fit: cover;
}

</style>
