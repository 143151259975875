<template>
  <div id="footer-section" class="footer-section">
    <div class="row container">
      <div class="col-md-4 col-sm-6">
        <aside class="widget widget_about">
          <h3 class="widget-title text-white">Nosotros</h3>
          <p class="text-white">
            Somos la mejor opción para la compra, venta y renta de inmuebles en
            la Ciudad de México. Nos respaldan 25 años de experiencia y miles de
            clientes satisfechos en las mejores zonas de la CDMX: Polanco,
            Condesa, Santa Fe, Roma, Pedregal, San Angel y San Jerónimo.
          </p>
        </aside>
      </div>

      <div class="col-md-4 col-sm-6">
        <aside class="widget widget_newsletter">
          <h3 class="widget-title text-white">NewsLetter</h3>
          <div class="input-group">
            <input
              type="text"
              id="pie-news"
              class="form-control"
              placeholder="Ingresa tu correo"
            />
            <span class="input-group-btn">
              <button
                class="btn btn-default"
                type="button"
                data-toggle="modal"
                data-target="#newsletter-modal"
                onClick="newsletter('pie-news')"
              >
                Ir
              </button>
            </span>
          </div>
          <p class="text-white">
            Recibe notificaciones de las nuevas propiedades que tenemos
          </p>
        </aside>
      </div>

      <div class="col-md-4 col-sm-6">
        <aside class="widget widget_newsletter">
          <h3 class="widget-title text-white">Propiedades Recientes</h3>
          <div class="foot-relast">
            <div class="item-re"></div>
            <div class="item-re desc">
              <p class="text-white"></p>
            </div>
          </div>
        </aside>
      </div>
    </div>

    <div class="foot-back"></div>

    <div id="footer-bottom" class="footer-bottom">
      <div class="container">
        <p class="col-md-4 col-sm-6 col-xs-12">
          &copy; 2020 Finca Inmobiliaria ‐ Todos los derechos reservados
        </p>
        <div class="col-md-4 col-sm-6 col-xs-12 pull-right social">
          <ul class="footer_social m_b_z">
            <li>
              <a href="#"><ion-icon name="logo-facebook"></ion-icon></a>
            </li>
            <li>
              <a href="#"><ion-icon name="logo-facebook"></ion-icon></a>
            </li>
          </ul>
          <a href="#" title="back-to-top" id="back-to-top" class="back-to-top"
            ><i class="fa fa-long-arrow-up"></i> Top</a
          >
        </div>
      </div>
    </div>
  </div>

  <a
    href="https://api.whatsapp.com/send?phone=+525584932033&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20propiedad%20"
    class="float d-flex justify-content-center align-items-center"
    target="_blank"
  >
    <ion-icon name="logo-whatsapp"></ion-icon>
  </a>
</template>
