<template>
  <!--div id="site-loader" class="load-complete">
    <div class="load-position">
      <div class="logo logo-block">
        <a href="<?=URL_SITIO?>"
          ><img src="<?=URL_SITIO?>/images/logo.png" alt="logo"
        /></a>
      </div>
      <h6>Tu casa, depa o negocio a un clic</h6>
      <div class="loading">
        <div class="loading-line"></div>
        <div class="loading-break loading-dot-1"></div>
        <div class="loading-break loading-dot-2"></div>
        <div class="loading-break loading-dot-3"></div>
      </div>
    </div>
  </div-->
  <!-- Loader /- -->

  <a id="top"></a>

  <header class="nueva-top">
    <div class="container d-flex justify-content-between">
      <div class="logo">
        <img
          src="https://fincabienesraices.com.mx/images/logo.png"
          alt="Logo Finca Bienes Raices"
        />
      </div>
      <nav class="navegador">
        <ul>
          <li>
            <router-link :to="{ name: 'Buscar' }"> Compra </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Buscar' }"> Renta </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Buscar' }"> Preventa </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Buscar' }"> Desarrollos </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Buscar' }"> Comercial </router-link>
          </li>
        </ul>
        <div class="contador">
          <p>Total de propiedades: 5120</p>
        </div>
      </nav>
    </div>
  </header>
</template>

<style>
header {
  padding: 10px 0;
}
header .logo img {
  width: 80px;
}
.navegador {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nueva-top .navegador ul {
  list-style: none;
  margin: 0;
  margin-right: 20px;
}
.nueva-top .navegador ul li {
  float: left;
  margin-left: 20px;
}
.nueva-top .navegador ul li a {
  padding: 0;
  font-size: 16px;
  font-family: "Karla", sans-serif;
  line-height: 38px;
  color: #3c3c44;
  text-decoration: none;
}
.nueva-top .contador {
  background-color: #ea0c0c;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 20px;
}
.nueva-top .contador p {
  margin: 0;
}
.nueva-top .navegador ul li a::before {
  content: " ";
  display: inline-block;
  margin-right: 18px;
  height: 8px;
  width: 8px;
  background-color: #e3e3e3;
  border-radius: 50%;
}
</style>
